<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Adicionar Post no Blog</h4>

          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'blog-posts' })"
            >
              <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
              Listar Posts do Blog
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="w-100 cursor-pointer">
                  <div class="uploadimg w-100 border p-5 text-center">
                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                    <h6 class="text-muted">clique para enviar imagem</h6>
                  </div>
                  <input
                    type="file"
                    @change="onFileChange"
                    name="image[]"
                    multiple
                    class="d-none"
                  />
                </label>
              </div>
              <div class="col-12" v-if="imgs.length > 0">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Imagem</th>
                        <th scope="col" class="text-center">Capa</th>
                         <th scope="col" class="text-center">Fundo</th>
                        <th scope="col" class="text-center">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="text-center align-middle"
                        v-for="(i, index) in imgs"
                        :key="index"
                      >
                        <td class="text-left align-middle">
                          <b-img width="100" :src="i.url" rounded></b-img>
                        </td>
                        <td class="align-middle">
                          <input
                            class="form-check-input position-static"
                            type="radio"
                            v-model="i.capa"
                            value="1"
                            @click="altCheckCapa(index)"
                          />
                        </td>
                        <td class="align-middle">
                          <input
                            @click="altCheckFundo(index)"
                            class="form-check-input position-static"
                            type="radio"
                            value="1"
                            v-model="i.fundo"
                          />
                        </td>
                        <td class="align-middle">
                          <b-button
                            variant="danger"
                            @click.prevent="removerImagem(index)"
                          >
                            <i
                              class="bx bx-trash font-size-16 align-middle"
                            ></i>
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12">
                <label>Vídeo</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Link do vídeo"
                    for="text"
                    v-model="video"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12" v-if="form.video != null && form.video != ''">
                  <iframe width="100%" height="315" :src="form.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-base">
                <label>
                  <span class="mr-1 text-danger">*</span>
                  Título
                </label>
                <b-form-group>
                  <b-form-input
                    placeholder="Título da página"
                    for="text"
                    v-model="form.title"
                    :class="{ 'is-invalid': form && $v.form.title.$error }"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 mb-base">
                <label>Subtítulo</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Subtítulo da página"
                    for="text"
                    v-model="form.sub_title"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6 mb-3">
                <label>Status</label>
                <multiselect
                  v-model="form.status"
                  :options="options_status"
                  class="helo"
                  label="text"
                  placeholder="Status"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-md-12 col-lg-6 mb-3">
                <label>
                  <span class="mr-1 text-danger">*</span>
                  Categoria
                </label>
                <multiselect
                  v-model="form.category_blog_id"
                  :class="{ 'is-invalid': form && $v.form.category_blog_id.$error }"
                  :options="options_categorys"
                  class="helo"
                  label="name"
                  placeholder="Categoria"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoading"
                  @search-change="searchCategory"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-12 mb-3">
                <b-form-group>
                  <label>Conteúdo</label>
                  <quill-editor v-model="form.content" ref="quillEditorA"></quill-editor>
                </b-form-group>
              </div>
              <div class="col-12 mt-3 text-right">
                <b-button variant="success" @click.prevent="salvarDados()">
                  <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                  Salvar
                </b-button>
                <b-button
                  variant="danger"
                  class="ml-2"
                  @click.prevent="$router.push({ name: 'blog-posts' })"
                >
                  <i class="bx bx-x font-size-16 align-middle mr-2"></i>
                  Cancelar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.uploadimg {
  border: 3px dashed #ccc !important;
}
.uploadimg:hover {
  cursor: pointer;
}
.is-invalid .multiselect__tags {
  border-color: #f46a6a !important;
  border: 1px solid #f46a6a !important;
}
</style>
<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    edit: {
      type: Object,
      default: function () {
        return null
      },
    },
  },
  components: {
    Multiselect,
  },
  watch:{
      "video":function(){
          if(this.video.includes('watch?v=')){
            var url = this.video.split("watch?v=")
            var u = url[1].split("&")
            this.form.video = url[0]+"/embed/"+u[0]
          }
        },
  },
  data() {
    return {
      video: '',
      form: {
        status: { value: 0, text: 'Ativo' },
        content: '',
        title: null,
        category_blog_id: null,
        video: '',
        sub_title: ''
      },
      options_status: [
        { value: 0, text: 'Ativo' },
        { value: 1, text: 'Bloqueado' },
      ],
      options_categorys: [],
      imgs: [],
      filtro: {
        status: 0,
        limit: 10,
      },
      isLoading: false,
    }
  },
  validations: {
    form: {
      title: { required },
      category_blog_id: { required },
    },
  },
  created() {
    this.listCategory()
    if (this.edit != null) {
      this.form = this.edit
      this.video = this.edit.video
      this.form.status = {
        value: this.edit.status,
        text: this.edit.status == 0 ? 'Ativo' : 'Bloqueado',
      }
      if (this.edit.category_blog != null && this.edit.category_blog.id > 0) {
        this.form.category_blog_id = this.edit.category_blog
      }

      if(this.edit.image != null && this.edit.image.length > 0){
        this.edit.image.forEach(element => {
          var g = {
            file: null,
            url: element.image300,
            capa: element.capa,
            fundo: element.fundo,
            id: element.id,
          }
          this.imgs.push(g)
        });
      }
    }
  },
  methods: {
    ...mapActions('blog', [
      'ActionCategoryBlogAll',
      'ActionCategoryBlogUpdateImage',
      'ActionCategoryBlogDeleteImage',
      'ActionPostBlogCreate',
      'ActionPostBlogUpdate'
    ]),
    salvarDados() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
          this.submitPost()
      }
    },

    async submitPost() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })

        let data = new FormData()
        this.form.category_blog_id = this.form.category_blog_id.id
        this.form.status = this.form.status.value
        this.form.video = this.video

        data.append('title', this.form.title)
        data.append('sub_title', this.form.sub_title)
        data.append('video', this.form.video)
        data.append('content', this.form.content)
        data.append('status', this.form.status)
        data.append('category_blog_id', this.form.category_blog_id)
        
        if (this.imgs.length > 0) {
          this.imgs.forEach((element) => {
            if (element.id == null) {
              data.append('image[]', element.file)
              data.append('capa[]', element.capa)
              data.append('fundo[]', element.fundo)
            }
          })
        }

        if (this.edit != null) {
          data.append('id', this.form.id)
          var dados = {
            data: data,
            id: this.form.id,
          }
          await this.ActionPostBlogUpdate(dados)
        } else {
          await this.ActionPostBlogCreate(data)
        }
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'blog-posts' })
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },

    onFileChange(e) {
      e.target.files.forEach((element, index) => {
        var g = {
          file: element,
          url: URL.createObjectURL(element),
          capa: 0,
          fundo: 0,
          id: null,
        }
        this.imgs.push(g)
        if (
          (this.edit != null && this.edit.image == null) ||
          this.edit == null || this.edit.image.length <= 0
        ) {
          this.imgs[0].capa = 1
          this.imgs[0].fundo = 1
        }
      })
    },

    removerImagem(e) {
      var img = this.imgs[e]
      this.imgs.splice(e, 1)
      if (img.id != null && img.id > 0) {
        this.ActionCategoryBlogDeleteImage(img.id).then((_) => {
          var c = 0
          if (img.capa == 1) {
            c = 1
          }
          if (img.fundo == 1) {
            c = c + 2
          }
          if (c > 0 && this.imgs.length > 0) {
            if (this.imgs[0].id == null) {
              if (c == 1) {
                this.imgs[0].capa = 1
              }
              if (c == 2) {
                this.imgs[0].fundo = 1
              }
              if (c == 3) {
                this.imgs[0].capa = 1
                this.imgs[0].fundo = 1
              }
            } else {
              if (c == 1) {
                this.imgs[0].capa = 1
              }
              if (c == 2) {
                this.imgs[0].fundo = 1
              }
              if (c == 3) {
                this.imgs[0].capa = 1
                this.imgs[0].fundo = 1
              }
              var fg = {
                id: this.imgs[0].id,
                data: this.imgs[0],
              }
              this.ActionCategoryBlogUpdateImage(fg)
            }
          }
        })
      } else {
        if (img.capa == 1 && this.imgs.length > 0) {
          this.imgs[0].capa = 1
        }
        if (img.fundo == 1 && this.imgs.length > 0) {
          this.imgs[0].fundo = 1
        }
      }
    },
    altCheckCapa(i) {
      var t = []
      this.imgs.forEach((element) => {
        if (element.id != null && element.capa == 1) {
          element.capa = 0
          var fg = {
            id: element.id,
            data: element,
          }
          this.ActionCategoryBlogUpdateImage(fg)
        }
        element.capa = 0
        t.push(element)
      })
      t[i].capa = 1
      if (t[i].id != null) {
        var fg = {
          id: t[i].id,
          data: t[i],
        }
        this.ActionCategoryBlogUpdateImage(fg)
      }
      this.imgs = t
    },
    altCheckFundo(i) {
      var t = []
      this.imgs.forEach((element) => {
        if (element.id != null && element.fundo == 1) {
          element.fundo = 0
          var fg = {
            id: element.id,
            data: element,
          }
          this.ActionCategoryBlogUpdateImage(fg)
        }
        element.fundo = 0
        t.push(element)
      })
      t[i].fundo = 1
      if (t[i].id != null) {
        var fg = {
          id: t[i].id,
          data: t[i],
        }
        this.ActionCategoryBlogUpdateImage(fg)
      }
      this.imgs = t
    },
    async listCategory() {
      try {
        this.isLoading = true
        await this.ActionCategoryBlogAll(this.filtro).then((res) => {
          if (this.edit != null) {
            res.data.data.list.splice(res.data.data.list.indexOf(this.edit), 1)
          }
          this.options_categorys = res.data.data.list
          this.isLoading = false
        })
      } catch (_) {
        this.options_categorys = []
      }
    },
    searchCategory(e) {
      this.filtro.search = e
      this.listCategory()
    },
  },
}
</script>
